import { primeInputs } from '@sfxcode/formkit-primevue'
import { createMultiStepPlugin } from '@formkit/addons'
import { createProPlugin, inputs } from '@formkit/pro'
import { es } from '@formkit/i18n'


const isCheckboxAndRadioMultiple = (node) => (node.props.type === 'checkbox' || node.props.type === 'radio' || node.props.type === 'primeDropdown') 
    && node.props.options

function addAsteriskPlugin (node) {
  node.on('created', () => {
    const isRequired = node.props.parsedRules.some(rule => rule.name === 'required');
    if (!isRequired) return
    
    const isMultiOption = isCheckboxAndRadioMultiple(node)
    
    // if we're going to modify the schema then we need
    // to update the schemaMemoKey so we don't get an 
    // invalid cached schema.
    //node.props.definition.schemaMemoKey = `required_${isMultiOption ? 'multi_' : ""}${node.props.definition.schemaMemoKey}`
    
    const schemaFn = node.props.definition.schema;
    const required_label = ' (required)'
    node.props.definition.schema = (sectionsSchema = {}) => {      
      if (isRequired) {
        if(isMultiOption) {
          sectionsSchema.legend = {
            children: ['$label', required_label]
          }
        } else {
          sectionsSchema.label = {
            children: ['$label', required_label]
          }
        }
      }
      return schemaFn(sectionsSchema);
    }
  })
}

const pro = createProPlugin('fk-81d848eb28a', inputs)

const config = {
    locales: { es },
    locale: 'en',
    inputs: primeInputs,
    plugins: [
        pro,
        createMultiStepPlugin(),
        addAsteriskPlugin
    ]
  }
  
export default config