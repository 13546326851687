import axios from 'axios';

class TextingGroupDataService {
    constructor() {
        this.baseUrl = process.env.VUE_APP_API_BASE;
    }

    async getGroups() {
        try {
            const response = await axios.get(`${this.baseUrl}/groups/list`)
            return response.data;
        } catch (error) {
            console.error(error);
            throw new Error('TextingGroupDataService - getGroups()');
        }
    }

    async get(groupId) {
        console.log('group id: ', groupId);
        try {
            const response = await axios.get(`${this.baseUrl}/groups/${groupId}`);
            return response.data;
        } catch (error) {
            console.error(error);
            throw new Error('TextingGroupDataService - get()');
        }
    }

    async save(payload) {
        try {
            const response = await axios.post(`${this.baseUrl}/groups`, payload);
            return response.data;
        } catch (error) {
            console.error(error);
            throw new Error('TextingGroupDataService - save()');
        }
    }

    async update(payload) {
        try {
            const response = await axios.put(`${this.baseUrl}/groups`, payload);
            return response.data;
        } catch (error) {
            console.error(error);
            throw new Error ('TextingGroupDataService - update()');
        }
    }
}

export default TextingGroupDataService;