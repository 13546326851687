import { createApp } from 'vue'
import App from './App.vue'
import Axios from '@/services/axios-plugin';

import { plugin, defaultConfig } from '@formkit/vue'
import '@formkit/addons/css/multistep'
import config from './formkit.config.js'

import PrimeVue from 'primevue/config'
//import "primevue/resources/themes/saga-blue/theme.css"
import './assets/sass/mnit-saga-blue-theme.scss'

import "primevue/resources/primevue.min.css"
import 'primeicons/primeicons.css'
import "primeflex/primeflex.css"

import './assets/sass/formkit-primevue.scss'
import './assets/sass/formkit-prime-inputs.scss'

import InputText from 'primevue/inputtext'
import Button from "primevue/button"
import Badge from "primevue/badge"
import Ripple from 'primevue/ripple'
import BadgeDirective from 'primevue/badgedirective'
import StyleClass from 'primevue/styleclass'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Card from 'primevue/card'
import ConfirmPopup from 'primevue/confirmpopup'
import ConfirmDialog from 'primevue/confirmdialog'
import Toast from 'primevue/toast'
import Dialog from 'primevue/dialog'
import ToastService from 'primevue/toastservice'
import ConfirmationService from 'primevue/confirmationservice'
import Calendar from 'primevue/calendar'
import Dropdown from 'primevue/dropdown'
import MultiSelect from 'primevue/multiselect'
import InputMask from 'primevue/inputmask'
import InputSwitch from 'primevue/inputswitch'
import Checkbox from 'primevue/checkbox'
import RadioButton from 'primevue/radiobutton'
import Message from 'primevue/message'
import Skeleton from 'primevue/skeleton'
import ProgressSpinner from 'primevue/progressspinner'
import InlineMessage from 'primevue/inlinemessage'
import Textarea from 'primevue/textarea'
import Panel from 'primevue/panel'
import Paginator from 'primevue/paginator'
import AutoComplete from 'primevue/autocomplete'
import FileUpload from 'primevue/fileupload'
import Accordion from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'
import InputNumber from 'primevue/inputnumber'
import Tag from 'primevue/tag'

import store from './store'
import authentication from './plugins/authentication'

import { createRouter } from './router'

import i18n from './i18n'

import mitt from 'mitt'

const toastBus = mitt()

const app = createApp(App)

const mountCallback=() => {
  const router = createRouter(app)
  app.use(router)
  app.use(Axios)
  app.use(PrimeVue)
  
  i18n.setup()
  app.use(i18n.vueI18n)

  app.component('Tag', Tag)
  app.component('InputText', InputText)
  app.component('InputNumber', InputNumber)
  app.component('Button', Button)
  app.component('Badge', Badge)
  app.component('DataTable', DataTable)
  app.component('Column', Column)
  app.component('Card', Card)
  app.component('ConfirmPopup', ConfirmPopup)
  app.component('ConfirmDialog', ConfirmDialog)
  app.component('Toast', Toast)
  app.component('Dialog', Dialog)
  app.component('Calendar', Calendar)
  app.component('Dropdown', Dropdown)
  app.component('MultiSelect', MultiSelect)
  app.component('InputMask', InputMask)
  app.component('InputSwitch', InputSwitch)
  app.component('Checkbox', Checkbox)
  app.component('Radiobutton', RadioButton)
  app.component('Message', Message)
  app.component('Skeleton', Skeleton)
  app.component('FileUpload', FileUpload)
  app.component('ProgressSpinner', ProgressSpinner)
  app.component('InlineMessage', InlineMessage)
  app.component('Textarea', Textarea)
  app.component('Panel', Panel)
  app.component('Paginator', Paginator)
  app.component('AutoComplete', AutoComplete)
  app.component('Accordion', Accordion)
  app.component('AccordionTab', AccordionTab)
  app.directive('ripple', Ripple)
  app.directive('badge', BadgeDirective)
  app.directive('styleclass', StyleClass)
  app.use(ToastService)
  app.use(ConfirmationService)

  app.use(plugin, defaultConfig(config))

  app.provide('toastBus', toastBus)

  app.use(store);

  app.mount('#app')
}

app.use(authentication, {
    realm: process.env.VUE_APP_IDAM_REALM,    
    clientId: process.env.VUE_APP_IDAM_CLIENT_ID,
    url: process.env.VUE_APP_IDAM_URL,
    redirectUri: process.env.VUE_APP_IDAM_REDIRECT_URI
  },
  mountCallback
  )

app.config.globalProperties.$appTitle = process.env.VUE_APP_TITLE
app.config.globalProperties.$appPortalName = process.env.VUE_APP_PORTAL_NAME

export { toastBus }