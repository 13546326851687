import { createI18n } from 'vue-i18n'
import messages from './messages'

export const defaultLocale = 'en'

let _i18n

function setup(options = { locale: defaultLocale }) {
  _i18n = createI18n({
    locale: options.locale,
    fallbackLocale: defaultLocale,
    messages,
  })
  setLocale(options.locale)
  return _i18n
}

function setLocale(newLocale) {
  _i18n.global.locale = newLocale
}

export default {
  get vueI18n() {
    return _i18n
  },
  setup,
  setLocale,
}

export const supportedLocales = {
  'en': { name: 'English' },
  'es': { name: 'Español' },
}