import Keycloak from 'keycloak-js'

export default {
  install: (app, options, callback) => {
    const keycloak = new Keycloak(options)

    keycloak.init({ onLoad: 'check-sso',
                    checkLoginIframe: false
                  })
        .then(authenticated => {
            if (authenticated) {
                app.config.globalProperties.$keycloak = keycloak
                callback()
            } else {
                // keycloak.login() // needed login-required
                app.config.globalProperties.$keycloak = keycloak
                callback()
            }
        })
        .catch(e => {
            console.log("error")
            console.log(e)
        })
  }
}


// app is the app object coming from use create app method in main.js
// options - any options passed in when adding our plugin to our vue instance