<template>
<Toast position="bottom-center" group="appError"/>
<div class="min-h-screen flex relative lg:static surface-ground">
    <div id="app-sidebar-2" class="bg-blue-800 h-screen hidden lg:block flex-shrink-0 absolute lg:static left-0 top-0 z-1 select-none" style="width:280px">
        <div class="flex flex-column h-full">
            <div class="flex align-items-center px-5 bg-gray-50 flex-shrink-0" style="height:60px">
                <img src="/images/logo-mdh-mn-h-blu_rgb.png" alt="MDH Logo" height="30">
            </div>
            <div class="overflow-y-auto mt-3">
                <ul class="list-none p-3 m-0">

                  <!-- Skipping registration requests for now. -->
<!--                    <li v-if="$keycloak.hasResourceRole('mdh-admin') || $keycloak.hasResourceRole('mnit-admin') ||-->
<!--                              ($keycloak.hasResourceRole('org-admin') && hasAcceptedRegistration()) -->
                    <li v-if="$keycloak.hasResourceRole('mdh-admin') || $keycloak.hasResourceRole('mnit-admin')">
                        <router-link style="text-decoration: none; color: inherit;" :to="'/'">
                            <a v-ripple class="flex align-items-center cursor-pointer p-3 hover:bg-orange-400 border-round text-bluegray-100 hover:text-bluegray-50
                                  transition-duration-150 transition-colors p-ripple">
                                <i class="pi pi-home mr-2"></i>
                                <span class="font-medium">{{$t('menu_opt_home')}}</span>
                            </a>
                        </router-link>
                    </li>

                    <li v-if="($keycloak.hasResourceRole('mdh-admin') && hasAccessToTextingGroup()) || $keycloak.hasResourceRole('mnit-admin')">
                        <Button v-ripple class="flex align-items-center cursor-pointer p-3 hover:bg-orange-400 border-round text-bluegray-100 hover:text-bluegray-50
                            transition-duration-150 transition-colors p-ripple" style="width: 15.5rem;"
                            v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'slidedown', leaveToClass: 'hidden', leaveActiveClass: 'slideup' }">
                            <i class="pi pi-home mr-2"></i>
                            <span class="font-medium">{{$t('menu_opt_admin')}}</span>
                            <i class="pi pi-chevron-down ml-auto"></i>
                        </Button>
                        <ul class="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out admin_dropdown">
                          <li>
                                <router-link style="text-decoration: none; color: inherit;" :to="'/campaignType'">
                                    <a v-ripple class="flex align-items-center cursor-pointer p-3 hover:bg-orange-400 border-round text-bluegray-100 hover:text-bluegray-50
                                    transition-duration-150 transition-colors p-ripple">
                                      <i class="pi pi-inbox mr-2"></i>
                                      <span class="font-medium">{{$t('menu_opt_admin_campaign_type')}}</span>
                                    </a>
                                </router-link>
                          </li>
                          <li>
                                <router-link style="text-decoration: none; color: inherit;" :to="'/parameter'">
                                    <a v-ripple class="flex align-items-center cursor-pointer p-3 hover:bg-orange-400 border-round text-bluegray-100 hover:text-bluegray-50
                                    transition-duration-150 transition-colors p-ripple">
                                        <i class="pi pi-inbox mr-2"></i>
                                        <span class="font-medium">{{$t('menu_opt_admin_parameter')}}</span>
                                    </a>
                                </router-link>
                            </li>
                            <li>
                                <router-link style="text-decoration: none; color: inherit;" :to="'/template'">
                                    <a v-ripple class="flex align-items-center cursor-pointer p-3 hover:bg-orange-400 border-round text-bluegray-100 hover:text-bluegray-50
                                    transition-duration-150 transition-colors p-ripple">
                                        <i class="pi pi-inbox mr-2"></i>
                                        <span class="font-medium">{{$t('menu_opt_admin_template')}}</span>
                                    </a>
                                </router-link>
                            </li>
                            <li>
                                <router-link style="text-decoration: none; color: inherit;" :to="'/query'">
                                    <a v-ripple class="flex align-items-center cursor-pointer p-3 hover:bg-orange-400 border-round text-bluegray-100 hover:text-bluegray-50
                                    transition-duration-150 transition-colors p-ripple">
                                        <i class="pi pi-inbox mr-2"></i>
                                        <span class="font-medium">{{ $t('menu_opt_admin_cohort') }}</span>
                                    </a>
                                </router-link>
                            </li>

                            <li>
                                <router-link style="text-decoration: none; color: inherit;" :to="'/application'">
                                    <a v-ripple class="flex align-items-center cursor-pointer p-3 hover:bg-orange-400 border-round text-bluegray-100 hover:text-bluegray-50
                                    transition-duration-150 transition-colors p-ripple">
                                        <i class="pi pi-inbox mr-2"></i>
                                        <span class="font-medium">{{$t('menu_opt_admin_appid')}}</span>
                                    </a>
                                </router-link>
                            </li>
                            <li>
                                <router-link style="text-decoration: none; color: inherit;" :to="'/language'">
                                    <a v-ripple class="flex align-items-center cursor-pointer p-3 hover:bg-orange-400 border-round text-bluegray-100 hover:text-bluegray-50
                                        transition-duration-150 transition-colors p-ripple">
                                        <i class="pi pi-inbox mr-2"></i>
                                        <span class="font-medium">{{$t('menu_opt_language')}}</span>
                                    </a>
                                </router-link>
                            </li>
                            <li v-if="store.state.keycloak.hasResourceRole('mnit-admin')">
                                <router-link style="text-decoration: none; color: inherit;" to="/groups">
                                    <a v-ripple class="flex align-items-center cursor-pointer p-3 hover:bg-orange-400 border-round text-bluegray-100 hover:text-bluegray-50
                                        transition-duration-150 transition-colors p-ripple">
                                        <i class="pi pi-inbox mr-2"></i>
                                        <span class="font-medium">{{$t('menu_opt_texting_group')}}</span>
                                    </a>
                                </router-link>
                            </li>
                        </ul>
                    </li>


                  <!-- Don't need registration request stuff right now, since we're not doing external providers at the moment. -->

<!--                    <li v-if="($keycloak.hasResourceRole('mdh-admin') || $keycloak.hasResourceRole('mnit-admin'))">-->
<!--                        <a v-ripple class="flex align-items-center cursor-pointer p-3 hover:bg-orange-400 border-round text-bluegray-100 hover:text-bluegray-50-->
<!--                              transition-duration-150 transition-colors p-ripple"-->
<!--                           v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'slidedown', leaveToClass: 'hidden', leaveActiveClass: 'slideup' }">-->
<!--                            <i class="pi pi-home mr-2"></i>-->
<!--                            <span class="font-medium">Registration Requests</span>-->
<!--                            <i class="pi pi-chevron-down ml-auto"></i>-->
<!--                        </a>-->

<!--                        <ul class="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">-->
<!--                            <li>-->
<!--                                <router-link style="text-decoration: none; color: inherit;" :to="{ name: 'userRegistrationRequests' }">-->
<!--                                    <a v-ripple class="flex align-items-center cursor-pointer p-3 hover:bg-orange-400 border-round text-bluegray-100 hover:text-bluegray-50-->
<!--                                    transition-duration-150 transition-colors p-ripple">-->
<!--                                        <i class="pi pi-inbox mr-2"></i>-->
<!--                                        <span class="font-medium">Your Registration Requests</span>-->
<!--                                    </a>-->
<!--                                </router-link>-->
<!--                            </li>-->

<!--                            <li v-if="store.state.keycloak.hasResourceRole('mdh-admin') || store.state.keycloak.hasResourceRole('mnit-admin')">-->
<!--                                <router-link style="text-decoration: none; color: inherit;" :to="{ name: 'allRegistrationRequests' }">-->
<!--                                    <a v-ripple class="flex align-items-center cursor-pointer p-3 hover:bg-orange-400 border-round text-bluegray-100 hover:text-bluegray-50-->
<!--                                    transition-duration-150 transition-colors p-ripple">-->
<!--                                        <i class="pi pi-inbox mr-2"></i>-->
<!--                                        <span class="font-medium">All Registration Requests</span>-->
<!--                                    </a>-->
<!--                                </router-link>-->
<!--                            </li>-->

<!--                            <li v-for="group in state.textingGroups" v-if="store.state.keycloak.hasResourceRole('mdh-admin') || store.state.keycloak.hasResourceRole('mnit-admin')">-->
<!--                                <router-link style="text-decoration: none; color: inherit;" :to="{ name: 'groupRegistrationRequests', params: { groupId: group.id }}">-->
<!--                                    <a v-ripple class="flex align-items-center cursor-pointer p-3 hover:bg-orange-400 border-round text-bluegray-100 hover:text-bluegray-50-->
<!--                                    transition-duration-150 transition-colors p-ripple">-->
<!--                                        <i class="pi pi-inbox mr-2"></i>-->
<!--                                        <span class="font-medium">{{ group.name }}</span>-->
<!--                                    </a>-->
<!--                                </router-link>-->
<!--                            </li>-->
<!--                        </ul>-->
<!--                    </li>-->

<!--                    <li v-else-if="($keycloak.hasResourceRole('org-admin'))">-->
<!--                        <router-link style="text-decoration: none; color: inherit;" :to="{ name: 'userRegistrationRequests' }">-->
<!--                            <a v-ripple class="flex align-items-center cursor-pointer p-3 hover:bg-orange-400 border-round text-bluegray-100 hover:text-bluegray-50-->
<!--                                    transition-duration-150 transition-colors p-ripple">-->
<!--                                <i class="pi pi-inbox mr-2"></i>-->
<!--                                <span class="font-medium">Your Registration Requests</span>-->
<!--                            </a>-->
<!--                        </router-link>-->
<!--                    </li>-->

                  <!-- Don't need Users pages right now, since we're not doing external providers at the moment -->

<!--                    <li v-if="(store.state.keycloak.hasResourceRole('mdh-admin') || store.state.keycloak.hasResourceRole('mnit-admin'))">-->
<!--                        <a v-ripple class="flex align-items-center cursor-pointer p-3 hover:bg-orange-400 border-round text-bluegray-100 hover:text-bluegray-50-->
<!--                              transition-duration-150 transition-colors p-ripple"-->
<!--                           v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'slidedown', leaveToClass: 'hidden', leaveActiveClass: 'slideup' }">-->
<!--                            <i class="pi pi-home mr-2"></i>-->
<!--                            <span class="font-medium">Users</span>-->
<!--                            <i class="pi pi-chevron-down ml-auto"></i>-->
<!--                        </a>-->

<!--                        <ul class="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">-->
<!--&lt;!&ndash;                            <li>&ndash;&gt;-->
<!--&lt;!&ndash;                                <router-link style="text-decoration: none; color: inherit;" :to="{ name: 'UsersView' }">&ndash;&gt;-->
<!--&lt;!&ndash;                                    <a v-ripple class="flex align-items-center cursor-pointer p-3 hover:bg-orange-400 border-round text-bluegray-100 hover:text-bluegray-50&ndash;&gt;-->
<!--&lt;!&ndash;                                    transition-duration-150 transition-colors p-ripple">&ndash;&gt;-->
<!--&lt;!&ndash;                                        <i class="pi pi-inbox mr-2"></i>&ndash;&gt;-->
<!--&lt;!&ndash;                                        <span class="font-medium">All Users</span>&ndash;&gt;-->
<!--&lt;!&ndash;                                    </a>&ndash;&gt;-->
<!--&lt;!&ndash;                                </router-link>&ndash;&gt;-->
<!--&lt;!&ndash;                            </li>&ndash;&gt;-->

<!--                            <li>-->
<!--                                <router-link style="text-decoration: none; color: inherit;" :to="{ name: 'UsersRoleView', params: { roleName: 'org-admin' }}">-->
<!--                                    <a v-ripple class="flex align-items-center cursor-pointer p-3 hover:bg-orange-400 border-round text-bluegray-100 hover:text-bluegray-50-->
<!--                                    transition-duration-150 transition-colors p-ripple">-->
<!--                                        <i class="pi pi-inbox mr-2"></i>-->
<!--                                        <span class="font-medium">org-admin</span>-->
<!--                                    </a>-->
<!--                                </router-link>-->
<!--                            </li>-->

<!--                            <li>-->
<!--                                <router-link style="text-decoration: none; color: inherit;" :to="{ name: 'UsersRoleView', params: { roleName: 'mdh-admin'}}">-->
<!--                                    <a v-ripple class="flex align-items-center cursor-pointer p-3 hover:bg-orange-400 border-round text-bluegray-100 hover:text-bluegray-50-->
<!--                                    transition-duration-150 transition-colors p-ripple">-->
<!--                                        <i class="pi pi-inbox mr-2"></i>-->
<!--                                        <span class="font-medium">mdh-admin</span>-->
<!--                                    </a>-->
<!--                                </router-link>-->
<!--                            </li>-->

<!--                            <li>-->
<!--                                <router-link style="text-decoration: none; color: inherit;" :to="{ name: 'UsersRoleView', params: { roleName: 'mnit-admin'}}">-->
<!--                                    <a v-ripple class="flex align-items-center cursor-pointer p-3 hover:bg-orange-400 border-round text-bluegray-100 hover:text-bluegray-50-->
<!--                                    transition-duration-150 transition-colors p-ripple">-->
<!--                                        <i class="pi pi-inbox mr-2"></i>-->
<!--                                        <span class="font-medium">mnit-admin</span>-->
<!--                                    </a>-->
<!--                                </router-link>-->
<!--                            </li>-->

<!--                        </ul>-->
<!--                    </li>-->
                </ul>
            </div>
            <div class="mt-auto">
                <hr class="mb-3 mx-3 border-top-1 border-none border-bluegray-600" />
                <ul class="list-none p-2 m-0 hidden origin-bottom animation-duration-150 overflow-hidden animation-ease-in-out">
                    <li>
                        <Button @click='logout' link>
                            <a v-ripple class="flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-bluegray-50
                            transition-duration-150 transition-colors p-ripple">
                              <i class="pi pi-sign-out mr-2"></i>
                              <span class="font-medium">{{$t('menu_opt_signout')}}</span>
                            </a>
                        </Button>
                    </li>
                    <li>
                        <p class="text-xs text-white" style="margin-top: 0; margin-bottom: 0; text-align: right;">
                            Version {{appVersion}}
                        </p>
                    </li>
                </ul>
                <Button v-ripple class="m-3 px-3 py-2 flex align-items-center hover:bg-bluegray-900 border-round cursor-pointer text-bluegray-100 hover:text-bluegray-50
                    transition-duration-150 transition-colors p-ripple" style="width: 15.5rem; "
                        v-styleclass="{ selector: '@prev', enterClass: 'hidden', enterActiveClass: 'slidedown', leaveToClass: 'hidden', leaveActiveClass: 'slideup'}">
                    <span class="font-medium">{{ nameOfUser }}</span>
                    <i class="pi pi-chevron-up ml-auto"></i>
                </Button>
            </div>
        </div>
    </div>
    <!--  -->
    <div class="min-h-screen flex flex-column relative flex-auto">
        <div class="flex justify-content-between align-items-center px-5 surface-section shadow-2 relative lg:static border-bottom-1 surface-border" style="height:60px">
            <div class="flex">
                <Button v-ripple class="cursor-pointer block lg:hidden text-700 mr-3 mt-1 p-ripple p-0" style="background: transparent; border: none"
                        v-styleclass="{ selector: '#app-sidebar-2', enterClass: 'hidden', enterActiveClass: 'fadeinleft', leaveToClass: 'hidden', leaveActiveClass: 'fadeoutleft', hideOnOutsideClick: true}">
                    <i class="pi pi-bars text-4xl"></i>
                </Button>
<!--                <a v-ripple class="cursor-pointer block lg:hidden text-700 mr-3 mt-1 p-ripple" tabindex="0"-->
<!--                    v-styleclass="{ selector: '#app-sidebar-2', enterClass: 'hidden', enterActiveClass: 'fadeinleft', leaveToClass: 'hidden', leaveActiveClass: 'fadeoutleft', hideOnOutsideClick: true}">-->
<!--                    <i class="pi pi-bars text-4xl"></i>-->
<!--                </a>-->
                <!-- <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <InputText type="text" class="border-none w-10rem sm:w-20rem" placeholder="Search" />
                </span> -->
                <span class="text-lg font-bold text-blue-700">{{$t('app_title')}}</span>
            </div>
<!--            <a v-ripple class="cursor-pointer block lg:hidden text-700 p-ripple"-->
<!--                v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'fadein', leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true }">-->
<!--                <i class="pi pi-ellipsis-v text-2xl"></i>-->
<!--            </a>-->
<!--            <ul class="list-none p-0 m-0 hidden lg:flex lg:align-items-center select-none lg:flex-row-->
<!--                surface-section border-1 lg:border-none surface-border right-0 top-100 z-1 shadow-2 lg:shadow-none absolute lg:static">-->
<!--                <LocaleSwitcher v-show="false"/>-->

<!--            </ul>-->
            </div>

            <!-- hoverover display of recent emails sent. -->
            <!-- <div v-if="state.showRecentEmails">
              <p v-for="item in state.emailList">{{ item }}</p>
            </div> -->


        <div class="p-5 flex flex-column flex-auto">
            <div class="border-0 surface-border border-round  flex-auto">
              <router-view />
            </div>
        </div>
    </div>
</div>
  <!-- <div class="container-fluid px-0"> -->
    <!-- <MNITMDHNavbar /> -->

  <!-- </div> -->
</template>

<script setup>
import { reactive, ref, computed, inject } from 'vue'
import { getCurrentInstance } from "vue"
import packageJson from '../package.json';
import LocaleSwitcher from '@/components/app/LocaleSwitcher'
import { useToast } from 'primevue/usetoast'
import store from '@/store/index.js';
import TextingGroupDataService from "@/services/TextingGroupDataService";
import RegistrationRequestDataService from "@/services/RegistrationRequestDataService";
import {useRoute, useRouter} from "vue-router";

const state = reactive({  
  keycloak: {},
  status: {},
  textingGroups: [],
  registrationRequests: [],
})

const toastBus = inject('toastBus')
const toast = useToast()

const appVersion = ref(packageJson.version)
const router = useRouter();
const route = useRoute();

state.keycloak = getCurrentInstance().appContext.config.globalProperties.$keycloak
store.commit('setKeycloak', state.keycloak);

const nameOfUser = computed(() => {
  if (state.keycloak && state.keycloak.tokenParsed) {
    return state.keycloak.tokenParsed.name
  } else {
    return "Anonymous"
  }
})

const textingGroupDataService = new TextingGroupDataService();
// const registrationRequestDataService = new RegistrationRequestDataService();

const logout = () => {
  state.keycloak.logout()
}

// const loadData = async () => {
//     // console.log('route name: ', useRoute());
//     console.log('has org role: ', store.state.keycloak.hasResourceRole('org-admin'));
//     state.textingGroups = await textingGroupDataService.getGroups();
//     store.commit('setTextingGroups', state.textingGroups);
//   // state.registrationRequests = await registrationRequestDataService.getRequests();
//   // findOwnership();
// }
// loadData()

toastBus.on('*', (type, args) => {
  if (type === 'add') {
    toast.add(args)
  } else if (type === 'remove') {
    toast.remove(args)
  }
})

const hasAccessToTextingGroup = async () => {
  state.textingGroups = await textingGroupDataService.getGroups();
  const currentUser = store.state.keycloak.tokenParsed.preferred_username;
  console.log('current user: ', currentUser);
  let hasAccess = false;
  for (let group of state.textingGroups) {
    console.log('group: ', group);
    for (let owner of group.ownerUsernames) {
      if (currentUser === owner) {
        hasAccess = true;
      }
    }
  }
  return hasAccess;
}

// const findOwnership = () => {
//   if (store.state.keycloak.hasResourceRole('mnit-admin')) {
//     state.textingGroups = store.state.textingGroups;
//     return;
//   }
//   let ownerOfGroups = [];
//   for (let group of store.state.textingGroups) {
//     for (let [, user] of Object.entries(group.ownerUsernames)) {
//       if (state.keycloak.tokenParsed.preferred_username === user) {
//         ownerOfGroups.push(group);
//       }
//     }
//   }
//   if (ownerOfGroups.length > 0) {
//     state.textingGroups = ownerOfGroups;
//   }
// }

// const hasAcceptedRegistration = () => {
//     console.log('in hasAcceptedRegistration');
//
//     if (store.state.keycloak.hasResourceRole('org-admin') && !(store.state.keycloak.hasResourceRole('mdh-admin')) && !(store.state.keycloak.hasResourceRole('mnit-admin'))) {
//         for (const request of state.registrationRequests) {
//             if (store.state.keycloak.tokenParsed.sub === request.idamId && request.status === 'approved') {
//                 return true;
//             }
//         }
//         return false;
//     }
// }

</script>

<style>
  body {
      font-family: var(--font-family);
  }
</style>
