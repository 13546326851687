import { createRouter as createVueRouter, createWebHistory } from 'vue-router'


const createRoutes = () => [
  {
    path: "/",
    name: "home",
    meta: {
      title: 'Campaign Dashboard',
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "hcp" */ "../views/welcome.vue"),

  },

  {
    path: "/query",
    name: "query",
    meta: {
      title: 'Message Queries',
      requiresAuth: true
    },
    component: () => import("../views/cohort/CohortSearchView.vue"),
  },

  {
    path: "/query/edit/:id?",
    name: "queryEditView",
    meta: {
      title: "Edit Query",
      requiresAuth: true
    },
    component: () => import("../views/cohort/CohortEditView.vue"),
  },

  {
    path: "/language",
    name: "language",
    meta: {
      title: 'Message Languages',
      requiresAuth: true
    },
    component: () => import("../views/language/LanguageSearchView.vue"),
  },

  {
    path: "/language/edit/:id?",
    name: "languageEditView",
    meta: {
      title: "Edit Language",
      requiresAuth: true
    },
    component: () => import("../views/language/LanguageEditView.vue"),
  },
   
  {
    path: "/template",
    name: "template",
    meta: {
      title: 'Message Templates',
      requiresAuth: true
    },
    component: () => import("../views/template/TemplateSearchView.vue"),    
  },

  {
    path: "/template/edit/:id?",
    name: "templateEditView",
    meta: {
      title: 'Edit Template',
      requiresAuth: true
    },
    component: () => import("../views/template/TemplateEditView.vue"),
  },

  {
    path: "/campaign/edit/:id?",
    name: "campaignEditView",
    meta: {
      title: 'Edit Campaign',
      requiresAuth: true
    },
    component: () => import("../views/campaign/CampaignEditView.vue"),
  },

  {
    path: "/signup",
    name: "OptInView",
    meta: {
      title: 'Text Message Signup',
      requiresAuth: false
    },
    component: () => import("../views/optin/OptInView.vue"),
  },

  {
    path: "/soon",
    name: "comingsoon",
    meta: {
      requiresAuth: true,
      authRoles: ['hcp']
    },
    component: () => import(/* webpackChunkName: "general" */ "../views/comingsoon.vue"),
  },
 
  {
    path: "/application",
    name: "applicationIdSearchView",
    meta: {
      title: 'Pinpoint Application Ids',
      requiresAuth: true
    },
    component: () => import("../views/applicationid/ApplicationIdSearchView.vue"),    
  },

  {
    path: "/application/edit/:id?",
    name: "applicationIdEditView",
    meta: {
      title: 'Edit Pinpoint Application Id',
      requiresAuth: true
    },
    component: () => import("../views/applicationid/ApplicationIdEditView.vue"),
  },

  {
    path: "/parameter",
    name: "ParameterSearchView",
    meta: {
      title: 'Parameters',
      requiresAuth: true
    },
    component: () => import("../views/parameter/ParameterSearchView.vue"),    
  },

  {
    path: "/parameter/edit/:id?/:textingGroup?",
    name: "parameterEditView",
    meta: {
      title: 'Edit Parameter',
      requiresAuth: true
    },
    component: () => import("../views/parameter/ParameterEditView.vue"),
  },

  {
    path: "/unauthorized",
    name: "UnauthorizedView",
    meta: {
      title: 'Unauthorized',
      requiresAuth: false,
    },
    component: () => import(/* webpackChunkName: "general" */ "../views/app/UnauthorizedView.vue")
  },

  {
    path: "/timeout",
    name: "TimeoutView",
    meta: {
      title: 'Session Timed Out',
      requiresAuth: false,
    },
    component: () => import(/* webpackChunkName: "general" */ "../views/app/TimeoutView.vue")
  },

  {
    path: "/error",
    name: "ErrorView",
    meta: {
      title: 'Application Error',
      requiresAuth: false,
    },
    component: () => import(/* webpackChunkName: "general" */ "../views/app/ErrorView.vue")
  },

  {
    path: "/users",
    name: "UsersView",
    meta: {
      title: 'Users',
      requiresAuth: true,
      authRoles: ['mdh-admin', 'mnit-admin']
    },
    component: () => import("../views/users/UsersSearchView.vue")
  },

  {
    path: "/users/role/:roleName?",
    name: "UsersRoleView",
    meta: {
      title: 'Role Users',
      requiresAuth: true,
      authRoles: ['mdh-admin', 'mnit-admin']
    },
    component: () => import("../views/users/UsersSearchView.vue")
  },

  {
    path: "/users/:id",
    name: "UserDetailsView",
    meta: {
      title: 'User Details',
      requiresAuth: true,
      authRoles: ['mdh-admin', 'mnit-admin']
    },
    component: () => import("../views/users/UserDetailsView.vue")
  },

  {
    path: "/groups",
    name: "TextingGroupsView",
    meta: {
      title: 'Texting Groups',
      requiresAuth: true,
      authRoles: ['mnit-admin']
    },
    component: () => import("../views/textingGroups/TextingGroupsSearchView.vue")
  },

  {
    path: "/groups/edit/:id?",
    name: "textingGroupEditView",
    meta: {
      title: 'Edit Texting Groups',
      requiresAuth: true,
      authRoles: ['mnit-admin']
    },
    component: () => import("../views/textingGroups/TextingGroupsEditView.vue")
  },

  {
    path: "/campaignType",
    name: "campaignTypeView",
    meta: {
      title: 'Campaign Types',
      requiresAuth: true,
    },
    component: () => import("../views/campaignType/CampaignTypeSearchView.vue")
  },

  {
    path: "/campaignType/edit/:id?",
    name: "campaignTypeEditView",
    meta: {
      title: 'Edit Campaign Type',
      requiresAuth: true,
    },
    component: () => import("../views/campaignType/CampaignTypeEditView.vue")
  },

  {
    path: "/registrations",
    name: "userRegistrationRequests",
    meta: {
      title: "Registration Requests",
      requiresAuth: true
    },
    component: () => import("../views/registrationRequests/RegistrationRequestsSearchView.vue")
  },

  {
    path: "/registrations/all",
    name: "allRegistrationRequests",
    meta: {
      title: "Registration Requests",
      requiresAuth: true,
      authRoles: ['mdh-admin', 'mnit-admin']
    },
    component: () => import("../views/registrationRequests/RegistrationRequestsSearchView.vue")
  },

  {
    path: "/registrations/:groupId",
    name: "groupRegistrationRequests",
    meta: {
      title: 'Registration Requests',
      requiresAuth: true,
      authRoles: ['mdh-admin', 'mnit-admin']
    },
    component: () => import("../views/registrationRequests/RegistrationRequestsGroupSearchView.vue")
  },

  {
    path: "/register/:requestId?",
    name: "newRegistrationRequest",
    meta: {
      title: 'Register',
      requiresAuth: true,
    },
    component: () => import("../views/registrationRequests/RegistrationRequestsEditView.vue")
  },

  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import('../views/NotFoundView.vue')
  },
  
]

export const createRouter = (app) => {
  const router = createVueRouter({
    history: createWebHistory(),
    routes: createRoutes(),
  })



  router.beforeEach((to, from, next) => {
    const title = to.meta.title
    if (title) {
      document.title = title + " | " + process.env.VUE_APP_TITLE
    } else {
      document.title = process.env.VUE_APP_TITLE
    }

    if (to.meta.requiresAuth) {
      const basePath = window.location.toString()

      if (!app.config.globalProperties.$keycloak.authenticated) {
        app.config.globalProperties.$keycloak.login({ redirectUri: location.protocol + '//' + location.host + to.path })
      } else {
        if (to.meta.authRoles) {
          let hasRole = false
          for (var i = 0; i < to.meta.authRoles.length; i++) {
            if (app.config.globalProperties.$keycloak.hasResourceRole(to.meta.authRoles[i])) {
              hasRole = true
            }
          }
          if (hasRole) {
            next()
          } else {
            router.push('unauthorized') 
          }
        } else {
          next()
        }
      }
    } else {
      next()
    }
  })

  return router
}

