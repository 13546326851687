import { createStore } from 'vuex'

export default createStore({
  state: {
    keycloak: {},
    ownerOfTextingGroups: [],
    textingGroups: []
  },
  getters: {
  },
  mutations: {
    setKeycloak(state, _keycloak) {
      state.keycloak = _keycloak;
    },
    setOwnerOfTextingGroups(state, groups) {
      state.ownerOfTextingGroups = groups;
    },
    setTextingGroups(state, groups) {
      state.textingGroups = groups;
    }
  },
  actions: {
  },
  modules: {
  },
})
