export default {
    en: {
        app_title: 'Minnesota Department of Health Texting Admin',
        app_submit: 'Submit',
        app_cancel: 'Cancel',
        app_go_back: 'Go Back',
  
        email_help: 'This will be the email address where you will receive username/password reset instructions. If you are a health care practitioner, we suggest using a personal email address here.',
  
        page_hdr_dedup_queue: 'HCP Deduplication Queue',
        page_hdr_hcp_patient_dashboard: 'Patient Dashboard',
        page_hdr_hcp_patient_pending_cert: 'Pending Patient Certifications',
        page_hdr_patient_cert: 'Patient Certification Request Form',
        page_hdr_patient_edit: 'Edit Patient',
        page_hdr_hcp_patient_search: 'Patient Search',
        page_hdr_hcp_enrollment_info:'Your HCP Enrollment information has been submitted!',
        page_hdr_registration_information:'Registration Information (Official Use)',
        page_hdr_dedup_que:'HCP Deduplication Queue - Detail Page',

        page_hdr_template_search: 'Templates',
        page_hdr_cohort_search: 'Queries',
        page_hdr_appid_search: 'Pinpoint Application Ids',
        page_hdr_parameter_search: 'Parameters',
        page_hdr_language_search: 'Languages',
        page_hdr_campaign_type_search: 'Campaign Types',

        header_basic_info: 'Basic Information',
        header_contact_info: 'Contact Information',
        header_needs_info: 'Needs',
        header_demographics_info: 'Demographics',
        header_generic_info: 'Information',
        header_medical_diagnosis: 'Medical Diagnosis',
        header_notes: 'Notes',
        header_ack_and_permissions: 'Acknowledgement and Permissions',
        header_search: 'Search',
        header_change_information_request:'Change of Information Request',
        header_request_document_upload:'Requested Document Uploads',
        header_request_update:'Requested Updates',
        header_personal_information:'Personal Information',
        header_credentials:'Credentials',
        header_clinic_information:'Clinic Information',
        header_email_sent_message:'Email Messages Sent',
        header_hcp_account_request:'HCP Account Request',
        header_possible_accounts:'Possible HCP Account Matches',
        header_merging_existing_accounts:'Merge Existing HCP Account',

        prompt_new_program: 'Is this a new patient to the program?',
        prompt_patient_email: 'Please use exact email address to ensure the patient receives the enrollment link and instructions. Note: If this is a minor patient, or a patient under legal guardianship, enter the parent or legal guardian\'s email address.',
        prompt_page_not_found:'Not Found',

        paragraph_preferred_language: 'If patient\'s preferred language is Spanish, Somali, or Hmong, please select the appropriate language below IF they would like the enrollment application mailed to them in that language (select one).',
        paragraph_preferred_language_note:'Note: Patient will still receive an online application via email (in English), that they can complete.',
        paragraph_mail_enrollment_message: 'Where should patient\'s enrollment application be mailed?',
        paragraph_clinic_information: 'Please specify the clinic name where the patient is being certified. Or, if via telemedicine, please specify (NOTE: telemedicine certifications are only allowed for recertification, where patients have been enrolled in the program before).',
        paragraph_medical_condition:'Please select all medical conditions that qualify this patient for the medical cannabis program.',
        paragraph_ack_permission_message:'Pharmacists at the medical cannabis dispensaries occasionally like being able to communicate with a patient\'s certifier. But, it requires your permission to share your contact information to the pharmacists. Would you like to have your contact information visible to the pharmacists in case they would like to contact you about this patient, specifically your name and email address?',
        paragraph_acknowledgement_text:'Please check this box to review and accept the legal acknowledgement, consent, and disclosure statements.',
        paragraph_drag_drop:'Drag and drop files to here to upload.',
        paragraph_uploaded:'You have uploaded the following files:',
        paragraph_enrollment_info:'Office of Medical Cannabis staff will review your enrollment form. You will be informed of the review.',
        paragraph_page_unavailable:'Page is not available. Try going',
        paragraph_menu_info:'Use the menu bar on the left to select various areas of the application.',
        paragraph_dedup_data_merge_message:'Select which data attribute to use in the merge.',
        paragraph_pending_requests_info: 'Below are the pending requests for Texting Groups you manage.',

        template_no_account_dedup:'No account requests for deduplication found.',
        template_loading_dedup:'Loading deduplication work queue. Please wait.',
        template_loading_matches:'Loading matches for deduplication. Please wait.',
        template_unexpected_error:'Unexpected Error',
        template_error_message:'We encountered an error processing your request.',
        template_session_timeout:'Session has Timed Out',
        template_timeout_message:'Log back in...',
        template_unauthorize:'Unauthorized Access',
        template_unauthorize_message:'You do not have the necessary permissions to access this page.  If you feel this is in error, please contact:',


        actor_patient: 'Patient',
        actor_plg: 'Parent/Legal Guardian',
        actor_clinic: 'Clinic',

        domain_template: 'Template',
        domain_campaign: 'Campaign',
        domain_cohort: 'Query',
        domain_application: 'Application Id',
        domain_parameter: 'Parameter',
        domain_language: 'Language',
        domain_texting_group: 'Texting Group',
        domain_registration_request: 'Registration Request',
        domain_campaign_type: 'Campaign Type',

        label_first_name: 'Legal First Name',
        label_last_name: 'Legal Last Name',
        label_middle_initial: 'Middle Initial',
        label_email_address: 'Email Address',
        label_phone_number: 'Phone Number',
        label_patient_id: 'Patient ID',
        label_dob: 'Date of Birth',
        label_allow_pharm_hcp_contact: 'Allow Pharmacist to contact HCP?',

        label_code: 'Code',

        menu_opt_home: 'Campaign Dashboard',
        menu_opt_patient: 'Patients',
        menu_opt_patient_certification: 'Enter Patient Certification',
        menu_opt_patient_pending_cert: 'Pending Patient Certifications',
        menu_opt_patient_active: 'Active Patients',
        menu_opt_patient_search: 'Patient Search',
        menu_opt_hcp: 'HCPs',
        menu_opt_hcp_enrollment: 'Enrollment',
        menu_opt_more: 'More to Come...',
        menu_opt_signout: 'Sign Out',

        menu_opt_admin: 'Admin',
        menu_opt_admin_template: 'Templates',
        menu_opt_admin_cohort: 'Queries',
        menu_opt_admin_appid: 'Application Ids',
        menu_opt_admin_parameter: 'Parameters',
        menu_opt_language: 'Languages',
        menu_opt_texting_group: 'Texting Groups',
        menu_opt_admin_campaign_type: 'Campaign Type',
  
        col_hdr_id: 'ID',
        col_hdr_username:'Username',
        col_hdr_requested_username:'Requested Username',
        col_hdr_full_legal_name:'Full Legal Name',
        col_hdr_first_name: 'First Name',
        col_hdr_last_name: 'Last Name',
        col_hdr_survey_due: 'Survey Due',
        col_hdr_status: 'Status',
        col_hdr_governing_status: 'Govering Status',
        col_hdr_enrollment_status: 'Enrollment Status',
        col_hdr_enrollment_end_date: 'Enrollment End',
        col_hdr_patient_certified: 'Certified',
        col_hdr_certification_expiry: 'Certification Expiration',
        col_hdr_recert_elig: 'Recertification Eligibility',
        col_hdr_notes: 'Notes',
        col_hdr_email_address: 'Email Address',
        col_hdr_phone_number: 'Phone Number',
        col_hdr_date_of_birth:'Date of Birth',
        col_hdr_birth_date:'Birth Date',
        col_hdr_last_four_SSN:'Social Security Number (last four)',
        col_hdr_SSN: 'SSN',
        col_hdr_field:'Field',
        col_hdr_current_request:'Current Request',
        col_hdr_existing_account:'Existing Account',
        col_hdr_action:'Actions',
        col_hdr_roles: 'Assigned Roles',

        col_hdr_code: 'Code',
        col_hdr_title: 'Display Name',
        col_hdr_camp_id: 'Campaign ID',
        col_hdr_camp_title: 'Campaign Title',
        col_hdr_query_id: 'Query Code',
        col_hdr_query_title: 'Query Title',
        col_hdr_param_id: 'Parameter Code',
        col_hdr_param_title: 'Parameter Label',
        col_hdr_template_id: 'Template Code',
        col_hdr_template_title: 'Template Title',
        col_hdr_text_group_id: 'Texting Group ID',
        col_hdr_text_group_title: 'Texting Group Title',


        query_help_text: 'To get a list of people for your campaign, we use a special search, called queries, in our MDH databases. MDH has already set up several of these searches for you to use. To make sure you\'re reaching the right people for your campaign, you might need to give us some specific details like your organization\'s ID or the county you\'re focusing on.',
        query_param_help_text: 'To make sure we get the right list of people for you, we need some more information from you. If you\'re not sure what to give us, don\'t worry - MDH is here to help you figure it out.',
        
        param_help_text: 'Templates let MDH send text messages that are almost the same to many people at the same time. MDH has created a bunch of these templates for you. In these templates, there are special areas, called parameters, marked like &#123;&#123;this&#125;&#125;. We can fill these areas with specific information that you give us in the spaces provided below.',
        camp_sch_help_text: 'Please tell us when you\'d like us to send the text messages. If you need to, you can also pause the sending of texts for a while.',
        camp_actg_help_text: 'This section indicates which MDH business unit should pay for the campaign. Please get in touch with MNIT to make sure this information is filled out correctly.',
        camp_id_help_text: 'These details help us recognize and name the campaign. The Campaign ID is a short code we use in reports. The Campaign Title is a longer, more descriptive name.',
        param_type_text: 'When you specify the kind of information you\'re entering for a parameter, the campaign editor checks it to make sure it\'s correct and useful.',
        text_grp_help_text: 'These details help us recognize and name the texting group. The Texting Group ID is a short code we use in reports. The Texting Group Title is a longer, more descriptive name.',
        text_grp_actg_help_text: 'This section indicates which MDH business unit should pay for the texting group. Please get in touch with MNIT to make sure this information is filled out correctly.',
        reg_req_personal_help_text: 'These details help us recognize the individual user that is creating this request.',
        reg_req_help_text: 'These details help us recognize the organization this request is for and what MDH branch to request access to.',
        tos_text: 'You must read and accept the Terms of Service before you can continue with the application.',

        page_intro_campaign: 'This screen is used to create or edit existing campaigns and is intended to be used by authorized users to manage texting campaign details.',
        page_into_parameter: 'A parameter field is like a blank spot in a message template or query template. Users fill in this spot with specific details each time they set up a new campaign.',
        page_intro_query: 'To get a list of people for your campaign, we use a special search, called queries, to select from MDH databases.',
        page_intro_template: 'Templates let MDH send text messages that are almost the same to many people at the same time.  In these templates, there are special areas, called parameters, marked like &#123;&#123;this&#125;&#125;. These parameter values are updated when the campaign is created.',
        page_intro_texting_group: 'This screen is used to create or edit existing texting groups and is intended to be used by authorized users to manage texting group details.',
        page_intro_registration_requests: 'This screen is used to create or edit existing registration requests you\'ve created to request access to the rest of the application.',
        page_intro_group_registration_requests: 'This screen is used to review existing registration requests for texting groups you manage to allow access to the rest of the application.',
        page_intro_users: 'This screen is used to view all users with a specific role.',
        page_intro_all_users: 'This screen is used to view all users.',
        page_intro_users_detail: 'This screen is used to view details about a specific user.',
        page_intro_campaign_type: 'Campaign types are a way to specify which type a campaign, query, and template are a part of in order to allow for easier campaign creation.',
    },
  
      es: {
  
        app_title: 'Registro de Cannabis de Minnesota',

        email_help: 'Esta será la dirección de correo electrónico donde recibirá las instrucciones para restablecer el nombre de usuario/contraseña. Si es un profesional de la salud, le sugerimos que use una dirección de correo electrónico personal aquí.',

        page_hdr_dedup_queue: 'Cola de Duplicación de HCP',
        page_hdr_hcp_patient_dashboard: 'Panel de Control del Paciente',
        page_hdr_patient_cert: 'Formulario de Solicitud de Certificación del Paciente',
        page_hdr_patient_edit: 'Editar Paciente',
        page_hdr_hcp_patient_search: 'Búsqueda de Pacientes HCP',

        header_basic_info: 'Información Básica',
        header_contact_info: 'Información de Contacto',
        header_needs_info: 'Necesidades',
        header_demographics_info: 'Información Demográfica',

        prompt_new_program: '¿Es este un nuevo paciente en el programa?',
        prompt_patient_email: 'Utilice la dirección de correo electrónico exacta para asegurarse de que el paciente reciba el enlace de inscripción e instrucciones. Nota: Si el paciente es menor de edad o está bajo la tutela legal, ingrese la dirección de correo electrónico del padre o tutor legal.',

        actor_patient: 'Paciente',
        actor_plg: 'Padre/Tutor Legal',

        label_first_name: 'Primer Nombre Legal',
        label_last_name: 'Apellido Legal',
        label_middle_initial: 'Inicial del Segundo Nombre',
        label_email_address: 'Dirección de Correo Electrónico',
        label_phone_number: 'Número de Teléfono',

        menu_opt_home: 'Inicio',
        menu_opt_patient: 'Pacientes',
        menu_opt_patient_certification: 'Ingresar Certificación de Paciente',
        menu_opt_patient_active: 'Pacientes Activos',
        menu_opt_patient_search: 'Búsqueda de Pacientes',
        menu_opt_hcp: 'Profesionales de la Salud (HCPs)',
        menu_opt_hcp_enrollment: 'Inscripción de HCP',
        menu_opt_more: 'Más por Venir...',
        menu_opt_signout: 'Cerrar Sesión',

        col_hdr_id: 'ID',
        col_hdr_first_name: 'Primer Nombre',
        col_hdr_last_name: 'Apellido',
        col_hdr_survey_due: 'Encuesta Debida',
        col_hdr_governing_status: 'Estado',
        col_hdr_enrollment_end_date: 'Fin de Inscripción',
        col_hdr_patient_certified: 'Certificado',
        col_hdr_certification_expiry: 'Vencimiento de Certificación',
        col_hdr_recert_elig: 'Elegibilidad de Recertificación',
        col_hdr_notes: 'Notas',
      }  
}